export enum WalletType {
    InstaCharge = '10',
    Paytm = '20',
    PhonePe = '30',
    NumadicFASTag = '40'
}

export enum SharedWalletStatus {
    active = '10',
    inactive = '20',
}

export enum WalletStatus {
    Linked = '10',
    NotLinked = '20',
}
