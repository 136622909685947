import { COLORS } from '../../config/colors';
import { AvailableAppVariants } from '../enums';
import { ThemeAssets, ThemeColorScheme, ThemeStyles } from '../types';
export const APP_CONFIG: {
  [theme: string]: {
    colorScheme: ThemeColorScheme;
    assets: ThemeAssets;
    styles: ThemeStyles;
  };
} = {
  [AvailableAppVariants.instacharge]: {
    colorScheme: {
      defaultStatusBar: COLORS.whiteColor,
      primaryColor: COLORS.primaryColor,
      primaryBorder: COLORS.primaryLight,
      bgColor: COLORS.lightPrimaryBackgroundColor,
      bgHeader: COLORS.whiteColor,
      textColor: COLORS.blackColor,
      bgDark: COLORS.primaryColor,
      borderColor: COLORS.primaryLight,
      inputBorder: COLORS.blackColor,
      buttonBgColor: COLORS.blackColor,
      buttonBgColor2: COLORS.cyanColorLight,
      sectionHeader: COLORS.blackColor,
      // semi generic
      countBadgeText: COLORS.darkBlack,
      countBadgeBg: COLORS.whiteColor,
      cardUnderlayBgColor: COLORS.grey100,
      cardBorderColor: COLORS.primaryLight,
      cardSelectedBgColor: COLORS.cyanColor,
      cardSelectedBgUnderlayColor: COLORS.cyanColor2,
      cardUnselectedBgColor: COLORS.whiteColor,
      cardSelectedTextColor: COLORS.darkBlack,
      cardUnselectedTextColor: COLORS.darkBlack,
      // components
      selectedPaymentMethodNameText: COLORS.darkBlack,
      unselectedPaymentMethodNameText: COLORS.darkBlack,
      walletBarAppWalletBgColor: COLORS.blackTint,
      walletBarNonAppWalletBgColor: COLORS.whiteColor,
      quickFilterItemSelectedBg: COLORS.primaryDark,
      quickFilterItemSelectedText: COLORS.darkBlack,
      quickFilterItemUnselectedText: COLORS.darkBlack,
      nearestCSContainerText: COLORS.whiteColor,
      nearestCSContainerSubText: COLORS.grey400,
      nearestCSContainerBg: COLORS.darkBlack,
      qrCodeTopHalfBg: COLORS.primaryColor,
      qrCodeBottomHalfBg: COLORS.skyBlue
    },
    assets: {
      FullLogoVector: 'InstaChargeLogo',
      FullLogoUrl: require('./../../assets/image/logo.png')
    },
    styles: {
      input: {
        borderWidth: 1,
        // borderBottomWidth: 3,
        borderColor: COLORS.blackColor,
        backgroundColor: COLORS.whiteColor,
        borderRadius: 8,
        height: 48
        // marginHorizontal: -10
      },
      // buttonBorder: {
      //   borderBottomWidth: 1
      // },
      homeScreenLogo: {
        width: 120,
        height: 25
      }
    }
  },
  [AvailableAppVariants.thunderplus]: {
    colorScheme: {
      defaultStatusBar: COLORS.whiteColor,
      primaryColor: COLORS.ThunderPlusPrimary,
      primaryBorder: COLORS.ThunderPlusPrimaryLight,
      bgColor: COLORS.whiteColor,
      bgHeader: COLORS.whiteColor,
      textColor: COLORS.darkBlack,
      bgDark: COLORS.grey200,
      borderColor: '#5e5e5e',
      inputBorder: '#5e5e5e',
      buttonBgColor: COLORS.blackColor,
      buttonBgColor2: COLORS.whiteColor,
      sectionHeader: COLORS.ThunderPlusPrimary,
      // semi generic
      countBadgeText: COLORS.whiteColor,
      countBadgeBg: COLORS.ThunderPlusPrimary,
      cardUnderlayBgColor: COLORS.grey100,
      cardBorderColor: COLORS.ThunderPlusPrimary,
      cardSelectedBgColor: COLORS.ThunderPlusPrimary,
      cardSelectedBgUnderlayColor: COLORS.ThunderPlusPrimaryDark,
      cardUnselectedBgColor: COLORS.whiteColor,
      cardSelectedTextColor: COLORS.whiteColor,
      cardUnselectedTextColor: COLORS.darkBlack,
      // components
      selectedPaymentMethodNameText: COLORS.whiteColor,
      unselectedPaymentMethodNameText: COLORS.darkBlack,
      walletBarAppWalletBgColor: COLORS.ThunderPlusPrimary,
      walletBarNonAppWalletBgColor: COLORS.ThunderPlusPrimary,
      quickFilterItemSelectedBg: COLORS.ThunderPlusPrimary,
      quickFilterItemSelectedText: COLORS.whiteColor,
      quickFilterItemUnselectedText: COLORS.darkBlack,
      nearestCSContainerText: COLORS.whiteColor,
      nearestCSContainerSubText: COLORS.offWhite,
      nearestCSContainerBg: COLORS.ThunderPlusPrimary,
      qrCodeTopHalfBg: COLORS.ThunderPlusPrimary,
      qrCodeBottomHalfBg: COLORS.skyBlue
    },
    assets: {
      FullLogoVector: 'ThunderLogo',
      FullLogoUrl: require('./../../assets/image/thunder-logo.png')
    },
    styles: {
      input: {
        borderWidth: 1,
        // borderBottomWidth: 3,
        borderColor: '#5e5e5e',
        backgroundColor: COLORS.whiteColor,
        borderRadius: 3,
        height: 48,
        marginHorizontal: -10
      },
      buttonBorder: {
        borderWidth: 1
      },
      homeScreenLogo: {
        width: 120,
        height: 25
      }
    }
  },
  [AvailableAppVariants.zerofootprint]: {
    colorScheme: {
      defaultStatusBar: COLORS.whiteColor,
      primaryColor: COLORS.ZeroFootprintPrimary,
      primaryBorder: COLORS.ZeroFootprintPrimaryLight,
      bgColor: COLORS.whiteColor,
      textColor: COLORS.darkBlack,
      bgHeader: COLORS.whiteColor,
      bgDark: COLORS.grey200,
      borderColor: '#5e5e5e',
      inputBorder: '#5e5e5e',
      buttonBgColor: COLORS.ZeroFootprintPrimary,
      buttonBgColor2: COLORS.whiteColor,
      sectionHeader: COLORS.ZeroFootprintPrimary,
      // semi generic
      countBadgeText: COLORS.whiteColor,
      countBadgeBg: COLORS.ZeroFootprintPrimary,
      cardUnderlayBgColor: COLORS.grey100,
      cardBorderColor: COLORS.ZeroFootprintPrimary,
      cardSelectedBgColor: COLORS.ZeroFootprintPrimary,
      cardSelectedBgUnderlayColor: COLORS.ZeroFootprintPrimaryDark,
      cardUnselectedBgColor: COLORS.whiteColor,
      cardSelectedTextColor: COLORS.whiteColor,
      cardUnselectedTextColor: COLORS.darkBlack,
      // components
      selectedPaymentMethodNameText: COLORS.whiteColor,
      unselectedPaymentMethodNameText: COLORS.darkBlack,
      walletBarAppWalletBgColor: COLORS.ZeroFootprintPrimary,
      walletBarNonAppWalletBgColor: COLORS.ZeroFootprintPrimary,
      quickFilterItemSelectedBg: COLORS.ZeroFootprintPrimary,
      quickFilterItemSelectedText: COLORS.whiteColor,
      quickFilterItemUnselectedText: COLORS.darkBlack,
      nearestCSContainerText: COLORS.whiteColor,
      nearestCSContainerSubText: COLORS.offWhite,
      nearestCSContainerBg: COLORS.ZeroFootprintPrimary,
      qrCodeTopHalfBg: COLORS.ZeroFootprintPrimary,
      qrCodeBottomHalfBg: COLORS.ZeroFootprintSecondary
    },
    assets: {
      FullLogoVector: 'ZeroFootprintLogo',
      FullLogoUrl: require('./../../assets/image/zerofootprint-logo.jpg')
    },
    styles: {
      input: {
        borderWidth: 1,
        // borderBottomWidth: 3,
        borderColor: '#5e5e5e',
        backgroundColor: COLORS.whiteColor,
        borderRadius: 3,
        height: 48,
        marginHorizontal: -10
      },
      buttonBorder: {
        borderWidth: 1
      },
      homeScreenLogo: {
        width: 170,
        height: 40
      }
    }
  }
};