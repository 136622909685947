import { View } from "react-native";
import { ScreenComponent } from "../../../types/components";
import styles from "./styles";
import { ReactComponent as SplashPulseEnergyLogo } from "../../../assets/icons/pulse_energy_logo.svg";
import { useNavigation } from "@react-navigation/native";
import { GLOBAL_VARS, SCREEN_NAMES } from "../../../constants";
import { AvailableAppVariants } from "../../../theme/enums";

type Props = ScreenComponent & {};
export default function SplashScreen(props: Props) {
  const navigation = useNavigation<any>();

  setTimeout(() => {
    navigation.replace(SCREEN_NAMES.SelectLanguageScreen);
  }, 1500);

  return (
    <View style={styles.container}>
      {
        GLOBAL_VARS.AppVariant === AvailableAppVariants.gudz
          ?
          <div style={{width: '40%'}}>
            <img src="/gudz/gudz-logo.png" alt="gudz" style={{width: '100%'}} />
          </div>
          :
          <SplashPulseEnergyLogo style={styles.img} />
      }
    </View>
  );
}
