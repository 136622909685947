export enum BillRefundStatus {
    Initiated = 'Initiated',
    Pending = 'Pending',
    Failed = 'Failed',
    Completed = 'Completed',
};

export enum CostBreakupLineItemCode {
    Total = 'total',
    ChargingSessionCost = 'charging_session_cost',
    DiscountEstimate = 'discount_estimate',
    DiscountsApplied = 'discounts_applied', // used in cost breakup
    GST = 'gst',
    ProcessingFee = 'pg_processing_fee',
    UnpaidAmount = 'previous_unpaid_amount',
    ParkingFee = 'parking_fee',
    PulseProcessingFee = 'pulse_processing_fee',
    ServiceFeeForMinutes = 'service_fee_for_minutes', 
}

export enum UnpaidAmountStatus {
    Pending = '10',
    Paid = '20',
    Failed = '30',
}

export enum BillSettlementStatus {
    Initiated = '10',
    Pending = '20',
    Failed = '30',
    Completed = '40'
}
