import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { ScreenComponent } from "../../../miscellaneous/types/components";
import {
  APP_STATE,
  AvailableFilters,
  ChargingStationDetailScreenRouteParams,
} from "../../../types";
import { Coords } from "../../../central-atoms/types";
import { useHomeOtherChargingStationsPagination } from "../../../hooks";
import ChargingAction, {
  updateNearbyUCSMiniObjectsList,
  updateNearestUCSMiniObject,
  updateUCSMasterMap,
} from "../../../actions/charginStationAction";
import { AppliedFilters } from "../../../central-atoms/filters/types";
import {
  FetchLastUsedCSQueryParams,
  FetchNearbyCSQueryParams,
} from "../../../central-atoms/types/requests/charging/charging-station";
import { FiltersService } from "../../../services/FilterService";
import { ChargingStationRequestService } from "../../../miscellaneous/services/ChargingStationRequestService";
import {
  MiniUCS,
  MixedChargingStation,
} from "../../../central-atoms/types/charging/charging-station";
import { setHomeScreenNearbyUCSPagination } from "../../../actions/HomeAction";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import { CSFilterGroup } from "../../../central-atoms/filters/enums";
import {
  setAppliedFilters,
  setAppliedFiltersChangeVersion,
  setAvailableCSFilters,
  setConnectorTypesFlatList,
  setCustomFiltersApplied,
} from "../../../actions/filtersActions";
import FiltersRequestService from "../../../services/FiltersRequestService";
import { SCREEN_NAMES } from "../../../constants";
import { setUsersPhysicalLocation } from "../../../actions/mainActions";
import { FullScreenRipple } from "../../components/FullScreenRipple";
import { useIsFocused } from "@react-navigation/native";
import { View } from "react-native";
import { horizontalSize, verticalSize } from "../../../functions/responsive";
import Div100vh from "react-div-100vh";
import { showInfoToast } from "../../../helpers";
import { t } from "i18next";

type Props = ScreenComponent & {};
export default function SearchingScreen(props: Props) {
  const { navigation } = props;
  const isFocused = useIsFocused();
  const dispatch = useDispatch();

  const { token, userData } = useSelector((state: APP_STATE) => state.auth);

  const csId = useRef<string | undefined>(undefined);
  const evaluatedCoords = useRef<Coords | null>(null);

  const pagination = useHomeOtherChargingStationsPagination();

  const { setRecentCharger, setLastChargeTransaction } = ChargingAction;

  const [waitForInitialLoad, setWaitForInitialLoad] = useState<boolean>(true);

  const getNearbyChargingStations = (af: AppliedFilters) => {
    const queryParams: FetchNearbyCSQueryParams = {
      ...FiltersService.getAppliedFiltersParams(af),
      page: 1,
    };

    if (
      evaluatedCoords.current?.latitude &&
      evaluatedCoords.current?.longitude
    ) {
      queryParams.user_lat = evaluatedCoords.current?.latitude;
      queryParams.user_lng = evaluatedCoords.current?.longitude;
    }

    ChargingStationRequestService.fetchNearbyChargingStations(queryParams)
      .then(({ data }) => {
        const nearbyUCSs: MixedChargingStation[] = data;
        batch(() => {
          dispatch(updateUCSMasterMap(nearbyUCSs));

          if (data?.length > 0) {
            dispatch(
              setHomeScreenNearbyUCSPagination({
                page: pagination?.page + 1,
                hasNext: true,
              })
            );
          }

          // set nearest
          const foundNearestUCS = nearbyUCSs.find(
            (ucs) => ucs.charging_station.is_nearest
          );
          dispatch(
            updateNearestUCSMiniObject(
              foundNearestUCS
                ? {
                    id: `${foundNearestUCS?.central_charging_station.id}`,
                    primary_cs_id: foundNearestUCS?.charging_station.id,
                    created_on: "",
                  }
                : null
            )
          );

          // set other
          const otherNearbyMiniUSCList: MiniUCS[] = [];
          nearbyUCSs.forEach((ucs) => {
            if (!ucs.charging_station.is_nearest) {
              otherNearbyMiniUSCList.push({
                id: `${ucs.central_charging_station.id}`,
                primary_cs_id: ucs.charging_station.id,
                created_on: "",
              });
            }
          });
          dispatch(
            updateNearbyUCSMiniObjectsList(otherNearbyMiniUSCList, "merge")
          );
        });
      })
      .catch((e: any) => {
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error",
          },
        });
      });
  };

  const getLastUsedChargingStation = () => {
    const queryParams: FetchLastUsedCSQueryParams = {};
    if (
      evaluatedCoords.current?.latitude &&
      evaluatedCoords.current?.longitude
    ) {
      queryParams.user_lat = evaluatedCoords.current?.latitude;
      queryParams.user_lng = evaluatedCoords.current?.longitude;
    }

    ChargingStationRequestService.fetchRecentChargingSession(queryParams)
      .then(({ data }) => {
        dispatch(setRecentCharger(data));
        if (data?.charge_transaction)
          dispatch(setLastChargeTransaction(data?.charge_transaction));
      })
      .catch((e: any) => {
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error",
          },
        });
      });
  };

  const updateAppliedFilters = (
    availableFilters: AvailableFilters
  ): AppliedFilters => {
    const appliedFilters: AppliedFilters = {
      [CSFilterGroup.ConnectorTypes]: [],
      [CSFilterGroup.Brands]: [],
      [CSFilterGroup.Amenities]: [],
      [CSFilterGroup.Payable]: [],
      [CSFilterGroup.Accessibility]: [],
    };

    Object.keys(availableFilters).forEach((csFilterGroup: string) => {
      const filterGroup: CSFilterGroup = csFilterGroup as CSFilterGroup;
      availableFilters[filterGroup].filter_subgroup.forEach(
        (filterSubgroup) => {
          filterSubgroup.filters.forEach((filterItem) => {
            if (filterItem.selected) {
              if (!appliedFilters[filterGroup])
                appliedFilters[filterGroup] = [];
              appliedFilters[filterGroup].push(filterItem.value);
            }
          });
        }
      );
    });

    batch(() => {
      dispatch(setAppliedFilters(appliedFilters));
      dispatch(setAppliedFiltersChangeVersion());
      dispatch(
        setCustomFiltersApplied(
          FiltersService.getTotalAppliedFilters(appliedFilters) > 0
        )
      );
    });

    return appliedFilters;
  };

  const getCSFilters = (): Promise<AppliedFilters | void> => {
    return FiltersRequestService.fetchCSFilters()
      .then((response) => {
        dispatch(setAvailableCSFilters(response.data));
        dispatch(
          setConnectorTypesFlatList(
            response.data?.available_filters?.[CSFilterGroup.ConnectorTypes]
              ?.filter_subgroup
          )
        );

        return updateAppliedFilters(response.data.available_filters);
      })
      .catch((e: AxiosError) => {
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error",
          },
        });
        return Promise.reject(e);
      });
  };

  const initialLoad = async () => {
    // TODO: add isLoggedIn flag
    if (csId.current && token && userData) {
      navigation.navigate(SCREEN_NAMES.ChargingStationDetailScreen, {
        isDeepLinked: true,
        chargerIdType: "charging-station",
        chargerId: csId.current,
      } as ChargingStationDetailScreenRouteParams);
    }

    const af = await getCSFilters();

    if (af) {
      // this will fetch in bg and load up
      setWaitForInitialLoad(true);
      Promise.all([getNearbyChargingStations(af), getLastUsedChargingStation()])
        .then(() => {
          setWaitForInitialLoad(false);
          if (!csId.current) {
            setTimeout(() => {
              navigation.navigate(SCREEN_NAMES.MapScreen, {
                returnScreen: SCREEN_NAMES.SearchingScreen,
              });
            }, 500);
          }
        })
        .catch((e) => {
          setWaitForInitialLoad(false);
          ErrorHandlerService.handleError(e, {
            toastOptions: {
              type: "error",
            },
          });
        });
    }
  };

  const getCurrentLocation = async () => {
    if (navigator.geolocation) {
      if ('permissions' in navigator) {
        const result = await navigator.permissions?.query({ name: "geolocation" });
        if (result.state === "prompt") {
          getMyLocation();
        } else if (result.state === "granted") {
          getMyLocation();
        } else if (result.state === "denied") {
          dispatch(
            setUsersPhysicalLocation({
              latitude: 19.07609,
              longitude: 72.877426,
            })
          );
          initialLoad();
        }
      }
      else {
        // Check if geolocation is supported by the browser
        if (window.navigator.geolocation) {
          // Prompt user for permission to access their location
          console.log('attempting to fetch location');
          getMyLocation();
        } 
        else {
          // Geolocation is not supported by the browser
          console.error("Geolocation is not supported by this browser.");
        }
      }
    }
  };

  const getMyLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        dispatch(
          setUsersPhysicalLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        );
        initialLoad();
      },
      (error) => {
        dispatch(
          setUsersPhysicalLocation({
            latitude: 19.07609,
            longitude: 72.877426,
          })
        );
        initialLoad();

        let msg = t('WeCouldNotDetermineYourLocation');
        switch(error.code) {
          case error.PERMISSION_DENIED:
            msg = "Please grant location permission to view nearby chargers"
            break;
          case error.POSITION_UNAVAILABLE:
            msg = "Location information is unavailable"
            break;
          case error.TIMEOUT:
            msg = "We could not determine your location. Please enable location to view nearby chargers"
            break;
          // case error.UNKNOWN_ERROR :
          //   msg = "An unknown error occurred."
          //   break;
        }

        showInfoToast(msg);
      },
      {
        enableHighAccuracy: true,
        maximumAge: 1000 * 60 * 5,
        timeout: 5000,
      }
    );
  };

  useEffect(() => {
    // added timeout to let the entire screen come into view
    if (isFocused) {
      const timeoutId = setTimeout(() => {
        getCurrentLocation();
      }, 1000);

      // Cleanup the timeout on component unmount or if the location is obtained
      return () => clearTimeout(timeoutId);
    }
  }, [isFocused]);

  return (
    <View style={{ height: verticalSize(100), width: horizontalSize(100), overflow: 'hidden' }}>
      <FullScreenRipple />
    </View>
  );
}
