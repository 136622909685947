import { useRoute } from "@react-navigation/native";
import { useEffect, useRef, useState } from "react";
import { FlatList, KeyboardAvoidingView, SectionList, View } from "react-native";
import { ScreenComponent } from "../../../types/components";
import { useAppliedFilters, useAppliedFiltersCount, useUsersPhysicalLocation } from "../../../hooks";
import {
  FetchChargingStationsSuggestionsReqPayload,
  SearchSuggestionItem
} from "../../../central-atoms/types/requests/charging/charging-station";
import { FiltersService } from "../../../services/FilterService";
import { ChargingStationRequestService } from "../../../miscellaneous/services/ChargingStationRequestService";
import { ErrorHandlerService } from "../../../services/ErrorHandlerService";
import PlaceCard from "../../components/PlaceCard";
import { SCREEN_NAMES } from "../../../constants";
import {
  ChargingStationDetailScreenRouteParams,
  FiltersScreenRouteParams,
  MapsScreenRouteParams
} from "../../../types";
import ChargingStationCard from "../../components/ChargingStationCard";
import styles from "./styles";
import SearchBar from "../../components/SearchBar";
import CText from "../../components/CText";
import { COLORS } from "../../../config/colors";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import IconButton from "../../../miscellaneous/components/IconButton";
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter.svg";
import { StyleHelpers } from "../../../miscellaneous/constants/style-helpers";
import { MixedChargingStation } from "../../../central-atoms/types/charging/charging-station";
import { useTranslation } from "react-i18next";

type Props = ScreenComponent & {};
export default function SearchScreen(props: Props) {
  const { navigation } = props;
  const { t } = useTranslation();
  const usersPhysicalLocation = useUsersPhysicalLocation();
  const appliedFiltersCount = useAppliedFiltersCount();

  const [searchText, setSearchText] = useState("");
  const [searchedList, setSearchedList] = useState<SearchSuggestionItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [nearByList, setNearByList] = useState<any>([]);

  const route = useRoute();
  const routeParams: any = route.params;

  const appliedFilters = useAppliedFilters();

  useEffect(() => {
    getNearbyCS();
  }, []);

  const getSearchedList = (val: string): Promise<void> => {
    setLoading(true);
    const params: FetchChargingStationsSuggestionsReqPayload = {
      user_lat: usersPhysicalLocation?.latitude,
      user_lng: usersPhysicalLocation?.longitude,
      lat: usersPhysicalLocation?.latitude, // 13.073004
      lng: usersPhysicalLocation?.longitude, // 77.5938666
      q: val,
      ...FiltersService.getAppliedFiltersParams(appliedFilters)
    };

    return ChargingStationRequestService.fetchSuggestions(params)
      .then((response) => {
        setSearchedList(response.data);

        setLoading(false);
      })
      .catch((e: any) => {
        setLoading(false);
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error"
          }
        });
      });
  };

  const getNearbyCS = () => {
    const data: any = {
      user_lat: usersPhysicalLocation?.latitude,
      user_lng: usersPhysicalLocation?.longitude,
      lat: usersPhysicalLocation?.latitude, // 13.073004
      lng: usersPhysicalLocation?.longitude, // 77.5938666
      radius: 40,
      ...FiltersService.getAppliedFiltersParams(appliedFilters)
    };

    ChargingStationRequestService.fetchChargingStationsNearby(data)
      .then((response: { data: MixedChargingStation[] }) => {
        setNearByList(response.data);
      })
      .catch((e: any) => {
        ErrorHandlerService.handleError(e, {
          toastOptions: {
            type: "error"
          }
        });
      });
  };

  const renderNeayByResult = ({ item, index }: { item: any; index: number }) => {
    return (
      <View key={index} style={{ marginVertical: 8 }}>
        <ChargingStationCard
          source={"searchcs_screen"}
          mixedChargingStation={item}
          showPrimaryActionButton={false}
          showConnectors={false}
          showPower={false}
          showPrice={false}
          coords={{
            latitude: item?.ChargingStation?.charging_station?.latitude,
            longitude: item?.ChargingStation?.charging_station?.longitude
          }}
          onViewChargerPress={() => {
            navigation.navigate(SCREEN_NAMES.ChargingStationDetailScreen, {
              chargerId: item?.charging_station?.id,
              chargerIdType: "charging-station",
              isDeepLinked: false
            } as ChargingStationDetailScreenRouteParams);
          }}
        />
      </View>
    );
  };

  useEffect(() => {
    if (routeParams?.type === "filter" && routeParams?.changeId) {
      getSearchedList(searchText);
    }
  }, [routeParams?.type, routeParams?.changeId]);

  const interval = useRef<any>(null);

  useEffect(() => {
    clearTimeout(interval.current);
    interval.current = setTimeout(() => {
      getSearchedList(searchText);
    }, 400);
  }, [searchText]);

  const getSectionData = () => {
    const chargingStationSection: any = [];
    const placeSection: any = [];
    const sectionData: any = [];

    searchedList.forEach((item) => {
      if (item.hasOwnProperty("ChargingStation")) {
        chargingStationSection.push(item);
      } else {
        placeSection.push(item);
      }
    });

    if (chargingStationSection && chargingStationSection.length > 0) {
      sectionData.push({
        title: `${chargingStationSection.length} ${chargingStationSection.length === 1 ? "charger" : "chargers"} found`,
        data: chargingStationSection
      });
    }

    if (placeSection && placeSection.length > 0) {
      sectionData.push({
        title: "Find chargers in",
        data: placeSection
      });
    }

    return sectionData;
  };

  const renderItems = (item: any, index: any) => {
    return (
      <View key={index} style={{ marginVertical: 8 }}>
        {item.Place ? (
          <PlaceCard
            title={item?.Place?.main_text}
            address={item?.Place?.sub_text}
            onPress={() => {
              navigation.navigate(SCREEN_NAMES.MapScreen, {
                place: item,
                type: "search"
              } as MapsScreenRouteParams);
            }}
          />
        ) : (
          <ChargingStationCard
            source={"searchcs_screen"}
            mixedChargingStation={item.ChargingStation}
            showPrimaryActionButton={false}
            showConnectors={false}
            showPower={false}
            showPrice={false}
            coords={{
              latitude: item?.ChargingStation?.charging_station?.latitude,
              longitude: item?.ChargingStation?.charging_station?.longitude
            }}
            onViewChargerPress={() => {
              navigation.navigate(SCREEN_NAMES.ChargingStationDetailScreen, {
                chargerId: item?.ChargingStation?.charging_station?.id,
                chargerIdType: "charging-station",
                isDeepLinked: false
              } as ChargingStationDetailScreenRouteParams);
            }}
          />
        )}
      </View>
    );
  };

  return (
    <KeyboardAvoidingView style={styles.root}>
      <View style={styles.headerCont}>
        <SearchBar
          value={searchText}
          onChangeText={(val: string) => {
            setSearchText(val);
            // getSearchedList(val);
          }}
          onBackPress={() => {
            navigation.goBack();
          }}
          onSubmitEditing={() => {
            getSearchedList(searchText);
          }}
          loading={loading}
        />
        <IconButton
          icon={<FilterIcon fill={COLORS.darkBlack} height={18} width={18} />}
          showIndicator={!!appliedFiltersCount}
          indicatorText={`${appliedFiltersCount}`}
          btnContStyles={{
            height: 45,
            width: 45,
            alignItems: "center",
            justifyContent: "center"
          }}
          style={{
            marginLeft: StyleHelpers.pageSpacing.paddingLeft,
            height: 45
          }}
          onPress={() => {
            navigation.navigate(
              SCREEN_NAMES.FiltersScreen as never,
              {
                context: "search_screen"
              } as FiltersScreenRouteParams as never
            );
          }}
        />
      </View>
      <View
        style={{
          flex: 1
        }}>
        <View
          style={{
            position: "relative",
            zIndex: 10,
            marginTop: 10,
            flex: 1
          }}>
          {(searchedList && searchedList.length > 0) || searchText.length > 0 ? (
            <SectionList
              sections={getSectionData()}
              contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 16 }}
              renderItem={({ item, index }) => renderItems(item, index)}
              ListEmptyComponent={() => {
                return (
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                    <View style={styles.emptyIconCont}>
                      <SearchIcon fill={COLORS.whiteColor} height={26} width={26} />
                    </View>
                    {!!searchText?.trim() && (
                      <CText
                        value={t("noResult")}
                        size={15}
                        semiBold
                        style={{ marginVertical: 8, fontWeight: "600" }}
                      />
                    )}
                    <CText
                      value={!!searchText?.trim() ? t("pleaseTryAnotherKeyword") : t("searchForCSorPlace")}
                      size={13}
                      semiBold
                      style={{
                        marginVertical: 8,
                        textAlign: "center",
                        color: COLORS.secondaryBlackColor
                      }}
                    />
                  </View>
                );
              }}
              renderSectionHeader={({ section: { title } }) => (
                <CText
                  size={10}
                  bold
                  value={title}
                  style={{
                    color: COLORS.primaryGrey,
                    marginTop: 10,
                    fontWeight: "500"
                  }}
                />
              )}
            />
          ) : nearByList && nearByList.length > 0 ? (
            <View>
              <CText
                value={`${nearByList.length} ${nearByList.length === 1 ? "charger" : "chargers"} nearby`}
                size={10}
                bold
                style={{
                  color: COLORS.primaryGrey,
                  marginTop: 10,
                  paddingHorizontal: 16,
                  fontWeight: "500"
                }}
              />
              <FlatList
                data={nearByList}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 16 }}
                renderItem={renderNeayByResult}
                keyboardShouldPersistTaps={true}
              />
            </View>
          ) : null}
        </View>
      </View>
    </KeyboardAvoidingView>
  );
}
