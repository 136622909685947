import { StartChargingPayload } from '../central-atoms/types/requests/charging';
import { ReactElement } from 'react';
import { Route } from '@react-navigation/native';
import { CentralChargingStation, MixedChargingStation } from '../central-atoms/types/charging/charging-station';
import { Wallet } from '../central-atoms/types/wallet';
import { ChargeTransaction } from '../central-atoms/types/charging/charge-txns';

export type ISODateTime = string;
export type SelectedCSFilters = Partial<AppliedFilters>;
export type WalletConsumer = CentralUser & {};
export type FleerOperator = Org;
export declare type ToastType = string;
export declare type ToastPosition = 'top' | 'bottom';
export type Coords = {
  latitude: number;
  longitude: number;
};

export type CoordsShort = {
  lat: number;
  lng: number;
};

export type CoordsWithRadius = CoordsShort & {
  radius: number;
};

export type CentralMedia = {
  id: string;
  full_url: string;
  type: MediaType;
};

export type PrimaryMediaItem = {
  caption?: string;
  url: string;
  filename?: string;
  id: string;
  object_path: string;
  order: number | null;
  path?: string;
  res?: string;
  size?: string;
  type?: string;
};

// Upload file group type
export enum UploadFileGroup {
  UserProfile = 'user-profile',
  OrgLogo = 'org-logo',
  ChargingStations = 'charging-stations',
  ChargePoints = 'charge-points',
  BrandLogo = 'brand-logo',
  LocationPinPrivate = 'location-pin-private',
  LocationPinPrivateBig = 'location-pin-private-big',
  LocationPinCloud = 'location-pin-cloud',
  LocationPin = 'location-pin',
  LocationPinCloudBig = 'location-pin-cloud-big', // 'location-pin-cloud-big'
  LocationPinBig = 'location-pin-big', // 'location-pin-big'
  ConnectorLogo = 'connector-logo',
  PaymentReceipt = 'payment-receipt',
  VehicleOwner = 'vehicle-owner'
}

export enum UploadFileGroupPrefix {
  UserProfileMedia = 'upm',
  OrgLogo = 'ol',
  ChargingStations = 'cs',
  ChargePoints = 'cp',
  BrandLogo = 'bl',
  LocationPinPrivate = 'lpp',
  LocationPinPrivateBig = 'lppb',
  LocationPinCloud = 'lpc',
  LocationPin = 'lp',
  LocationPinCloudBig = 'lpcb',
  LocationPinBig = 'lpb',
  ConnectorLogo = 'conl',
  PaymentReceipt = 'pr',
  VehicleOwner = 'vrn'
}

export enum MediaType {
  Logo = 'logo',
  CPImg = 'cp-img',
  CSImg = 'cs-img',
  VRNImg = 'vrn-img',
  DP = 'dp',
  LocationPinPrivate = 'location-pin-private',
  LocationPinPrivateBig = 'location-pin-private-big',
  LocationPinCloud = 'location-pin-cloud',
  LocationPinCloudBig = 'location-pin-cloud-big', // 'location-pin-cloud-big'
  LocationPin = 'location-pin',
  LocationPinBig = 'location-pin-big' // 'location-pin-big'
}

export type EvaluatedAppUpdate = {
  hasUpdate: boolean;
  mandatory: boolean;
};

export type RemoteConfigValues = {
  app_versions_list: AppVersionItem[];
};

export type AppVersionItem = {
  version: string;
  mandatory: boolean;
};

export type APP_STATE =
  | any
  | {
      main: MainState;
      auth: {
        centralUserData: CentralUser;
        userData: PrimaryUser;
        currentLanguage: string;
        currentLocation: {
          latitude: number;
          longitude: number;
        };
        fcmToken: string;
        activeFleetOperatorOrgUser: OrgUser;
        partialLogin: boolean;
        token: string;
      };
      chargingStation: ChargingStationState;
      chargeTxn: ChargeTxnState;
      doCharging: DoChargingState;
      filters: FiltersState;
      wallet: WalletState;
    };

export type MainState = {
  app: {
    appState: undefined;
    appInitialized: boolean;
    userAuthenticated: boolean;
    locPermissionEnabled: boolean;
    gpsEnabled: boolean;
    usersPhysicalLocation: Coords;
    mapCoords: Coords & { radius: number };
    allowedCPQrCodePatterns: string[];
    showACChargersOnly: boolean | null;
    shouldUpdateUserProfile: boolean | null;
    shouldFetchWalletBalance: boolean | null;
  };
  data: {};
};

export type CentralUser = MiniUser & {
  access: string[] | null;
  aiid: string;
  created_on: ISODateTime;
  email: string | null;
  primary_user_id: bigint | null;
  type: UserType;
  phone: string | null;
  invite_code: string | null;
  invite_status: InviteStatus | null;
  org_users: OrgUser[];
  contact_numbers: string[];
  active_org_ids: string[];
  active_fleet_id: string | null;
  active_lang: LangCodes;
  central_token: string;
  medias: any;
  preferences?: UserPreferences;
  active_wallet_id: string | null;
  active_payment_method: PaymentMethodType | null;
  active_wallet: Wallet | null;
  active_shared_wallet: SharedWallet | null;
  active_shared_wallet_id: string | null;
};

export type PrimaryUser = {
  accent_color: string;
  account_status: number;
  active: boolean;
  email: string | null;
  flags: null;
  id: number;
  invite_code: string;
  media: any | null;
  name: string;
  nickname: string | null;
  phone: string;
  roles: any | null;
  token: string;
  user_uid: string;
  vehicles: any[];
};

export type OrgUser = {
  id: string;
  aiid: string;
  org_id: string;
  org: Org | null;
  user_id: string;
  user?: CentralUser | null;
  types: string[];
  created_on: ISODateTime;
};

export type ChargingStationState = {
  filterData: {
    connector_types: any[];
    brands: any[];
  };
  nearestCharger?: MixedChargingStation;
  recentCharger?: MixedChargingStation;
  selectedCharger: any; // selectedChargingStation: any
  selectedChargerPoint: any; // selectedCPConnector: any
  selectedStation: any;
  nearbyChargingStations?: MixedChargingStation[];

  app: {
    homeOtherChargingStationsPagination: {
      page: number;
      hasNext: boolean;
    };
    trackedNearestChargingStation?: MixedChargingStation;
    lastChargeTransaction?: ChargeTransaction;
  };
  data: {
    masterUCSMap: { [key: string]: MixedChargingStation };
    mapsNearbyUCS: { [key: string]: MiniUCS };
    nearestUCSMiniObject: MiniUCS | null;
    otherNearbyUCSMiniObjectsList: Array<MiniUCS>;
  };
};

export type ChargeTxnState = {
  app: {
    chargeTxnsListPagination: {
      page: number;
      hasNext: boolean;
    };
    chargeTxnsFilters: {
      wallet_ids: string[];
    };
  };
  data: {
    chargeTxnsMap: {
      [chargeTxnId: string]: ChargeTransaction;
    };
    chargeTxnsList: Array<ChargeTransaction>;
  };
};

export type DoChargingState = {
  app: {
    selectedChargingStation: ChargingStation;
    selectedMixedChargingStation: MixedChargingStation;
    selectedChargePoint: ChargePoint;
    selectedChargePointConnector: ChargePointConnector;
    trackedCPErrorConnector: ChargePointConnector | null;
    trackedCPConnectionError: any | null;
  };
  data: {
    ongoingChargeTxns?: {
      [chargeTxnId: string]: ChargeTransaction;
    };
  };
};

export type FiltersState = {
  app: {
    customFiltersApplied: boolean;
    appliedFilters: AppliedFilters;
    appliedFiltersChangeVersion?: string;
  };
  data: {
    csFilters?: CSFiltersState; // global list of filters
  };
};

export type WalletState = {
  app: {};
  data: {
    activePaymentMethodType: PaymentMethodType | null;
    activeWallet: Wallet;
    fleetWallets: Wallet[];
    sharedWallets: Wallet[];
    personalWallets: Wallet[];
    walletConsumers: {
      [walletId: string]: SharedWallet[];
    };
  };
};

export type MiniUser = {
  id: string;
  name?: string;
  phone?: string;
  email?: string;
};

export enum UserType {
  Customer = 'Customer',
  SuperAdmin = 'SuperAdmin',
  SuperTeamMember = 'SuperTeamMember',
  CPOOwner = 'CPOOwner',
  CPOAdmin = 'CPOAdmin',
  CPOTeamMember = 'CPOTeamMember',
  FleetDriver = 'FleetDriver',
  StaffMember = 'StaffMember',
  ExternalVerdeMobility = 'ExternalVerdeMobility'
}

export type Org = {
  id: string;
  aiid: string;
  name: string;
  driver_access?: DriverAccess;
  operator_code: string | null;
  types: OrgType[];
  created_on: ISODateTime;
  medias: CentralMedia[];
  wallet?: Wallet;
  org_users: OrgUser[];
  additional_props?: OrgAdditionalProps;
};

export enum InviteStatus {
  Pending = 'Pending',
  Accepted = 'Accepted'
}

export enum LangCodes {
  en = 'en',
  hi = 'hi',
  kn = 'kn',
  bn = 'bn',
  ml = 'ml',
  mr = 'mr',
  gu = 'gu',
  ta = 'ta',
  te = 'te',
  empty = ''
}

export type UserPreferences = {
  version?: number;
  app_exp?: {
    selected_cs_filters?: SelectedCSFilters;
  };
  disable_smartlook?: boolean;
};

export enum PaymentMethodType {
  // Any enum added here should be added to the schema of the request where this enum is used. example: AppStartCharging, PostBoltStartChargingReq
  PaymentGateway = '10', // This covers all the payment done through CC, DC, UPI, Net Bankin
  Wallet = '20' // This covers all the payment done through Instacharge Wallet, Paytm Wallet, Shared Wallet
}


export type SharedWallet = {
  id: string;
  aiid?: string;
  wallet_consumer_id?: string;
  wallet_id?: string;
  access_code?: string | null;
  created_on?: ISODateTime;
  status?: SharedWalletStatus;
  additional_props?: WalletAdditionalProps;
  wallet?: Wallet;
  wallet_consumer?: WalletConsumer;
  amount_spent?: number;
};

export type MiniUCS = {
  id: string;
  primary_cs_id: number | string;
  created_on: string;
};

export type ChargingStation = {
  id: number;
  latitude: number;
  longitude: number;
  favorited: boolean | null;
  elevation: number | string;
  media: any | null;
  map_link: string;
  address: string;
  recent_check_in: any | null;
  provider: number | null;
  pricing: any | null;
  supported_charger_types: any | null;
  connectors: PrimaryChargingStationConnector[];
  phones: string[] | null;
  open_hours: string;
  average_rating: number | null;
  brand: string;
  total_reviews: number;
  name: string;
  check_in_count: number;
  brand_image: string;
  amenities: string[];
  charge_points: ChargePoint[];
};

export type ChargePoint = MiniChargePoint & {
  qr_code_id: string;
  vendor: string;
  model: string;
  code?: string;
  serial_number: string | null;
  charge_box_serial_number: string | null;
  iccid: string | null;
  imsi: string | null;
  grace_period: string;
  meter_type: string;
  meter_serial_number: null;
  firmware_version: string;
  firmware_update_status: null;
  ocpp_url: string;
  ocpp_version: string;
  ocpp_protocol: 'json';
  configuration: CPConfiguration | null;
  is_deleted: boolean;
  is_active: boolean;
  last_reboot: string | null;
  electrical_metrics: {};
  maximum_power: string | null;
  voltage: string | number | null;
  power_limit_unit: string | number | null;
  phase_type: string | number | null;
  phase_state: string;
  registration_status: RegistrationStatus | null;
  last_seen_on: string;
  evse_id: string;
  availability_status: string | number | null;
  diagnostics_upload_status: string | number | null;
  diagnostics_history: {};
  local_auth_list_version: string | number | null;
  qr_code: {};
  created_on: string;
  updated_on: string;
  cpo_id: string;
  oem_id: string;
  oem?: MiniOEM | null;
  org_id: string;
  accessibility: AccessibilityType;
  // connectors: ChargePointConnector[],
  // supported_charging_methods: ChargingMethod[],
  // controllability?: ControllabilityType[],
  connectivity_status: ConnectivityStatus;
  revenue_generated?: number;
  energy_delivered?: number;
  total_charge_transactions?: number;
  connectors: ChargePointConnector[] | undefined;
  charging_station?: CentralChargingStation;
  supported_charging_methods: ChargingMethod[];
  controllability: ControllabilityType[];
  ocpp_info: {
    secure: OCPPUrlInfoDetails;
    non_secure: OCPPUrlInfoDetails;
  };
};

export type ChargingMethod = {
  method: ChargingMethodType;
  amount: string | number;
  default?: boolean;
};

export enum ChargingMethodType {
  Default = 'Default',
  FullCharge = 'FullCharge',
  Amount = 'Amount',
  Units = 'Units',
  Time = 'Time',
  SoC = 'SoC'
}

export type OCPPUrlInfoDetails = {
  cp_id: string;
  domain: string;
  path: string;
  port: number;
  protocol: 'wss' | 'ws';
};

export enum ControllabilityType {
  OCPPControl = 10,
  ViewOnly = 20,
  APIControl = 30,
  OCPIControl = 40
}

export type ChargePointConnector = {
  id: string;
  public_uid: string;
  availability: AvailabilityType;
  status: ChargePointStatus;
  connector_id: number;
  primary_connector_id: string | null;
  type: string;
  charge_point?: ChargePoint;
  charge_point_id: string;
  qr_code: any;
  qr_code_id: string;
  electric_metric: string | null;
  price?: string;
  created_on: ISODateTime;
  error: ChargePointErrorCode | null;
  vendor_id: string | null;
  vendor_error_code: string | null;
  info: string | null;
  timestamp: ISODateTime | null;
  power_rating: number | string | null;
};

export type AppliedFilters = {
  [filter_group in CSFilterGroup]: string[];
};

export type CSFiltersState = {
  filter_group: FilterGroup[];
  available_filters: AvailableFilters;
};

export enum DriverAccess {
  Public = '10',
  Restricted = '20'
}

export enum OrgType {
  CPO = 'CPO', // "100", // Magenta(ChargeGrid), Zeon, SumiP
  FleetManager = 'FleetManager', // "200", // Log9
  FleetOperator = 'FleetOperator', // "210", // Magenta(EVET), Swiggy, Amazon, Flipkart
  ChargerOEM = 'ChargerOEM', // "300", // Magenta, Delta, ABB, Exicomm, Okaya
  EMSP = 'EMSP' // "400", // Pulse Energy, InstaCharge
}

export type OrgAdditionalProps = {
  billing_details?: BillingDetails[];
  console_theme?: OrgConsoleTheme;
  org_preferences?: OrgPreferences;
  custom_template_qr_code_template_file_name?: string;
  qr_code_template_font?: string;
  invoice_template_file_name?: string;
  console_host?: string;
};

export type OrgPreferences = {
  primary_currency: Currency;
  show_hub_views?: boolean;
  app_name?: string;
  app_icon_url?: string;
  app_link?: string; // Generic app install link
  app_install_link?: string; // app install CTA link
};

export enum Currency {
  INR = 'INR',
  GBP = 'GBP'
}

export enum WalletType {
  InstaCharge = '10',
  Paytm = '20',
  PhonePe = '30'
}

export enum SharedWalletStatus {
  active = '10',
  inactive = '20'
}

export enum WalletStatus {
  Linked = '10',
  NotLinked = '20'
}

export type WalletAdditionalProps = {
  state_token?: string;
  access_token?: string;
  refresh_token?: string;
  expiry?: string;
  device_id?: string;
  balance_updated_on?: string;
  sharee_name?: string;
};


export enum StopReason {
  DeAuthorized = 'DeAuthorized',
  EmergencyStop = 'EmergencyStop',
  EVDisconnected = 'EVDisconnected',
  HardReset = 'HardReset',
  Local = 'Local',
  Other = 'Other',
  PowerLoss = 'PowerLoss',
  Reboot = 'Reboot',
  Remote = 'Remote',
  SoftReset = 'SoftReset',
  UnlockCommand = 'UnlockCommand'
}

export enum ChargeTransactionStatus {
  Online = 'Online',
  Ongoing = 'Ongoing',
  Pending = 'Pending',
  Stopped = 'Stopped',
  Unknown = 'Unknown'
}

export enum ChargingSource {
  WhatsApp = 'WhatsApp',
  App = 'App',
  Console = 'Console',
  Charger = 'Charger',
  EVRE_RFID = 'EVRE_RFID',
  OCPI_RFID = 'OCPI_RFID',
  Vehicle = 'Vehicle'
}

export enum WhatsAppProtocol {
  UEI = 'uei',
  Everest = 'everest',
  InstaCharge = 'instacharge'
}

export enum AvailabilityType {
  Inoperative = 'Inoperative',
  Operative = 'Operative'
}
export type ChargeTransactionSummary = {
  time_elapsed?: number;
  energy_delivered?: number;
  current_soc?: number;
  initial_soc?: number;
  voltage?: number;
  current?: number;
  power?: number | null;
  last_timestamp?: ISODateTime;
  last_calculated_cost?: number;
};

export type Bill = {
  id: string;
  org: Org;
  bill_uid: string;
  payment_status: BillPaymentStatus | BillPaymentStatusStr;
  payment_method: BillPaymentMethod | null;
  refund_status: BillRefundStatus | null;
  refund_amount: string | null;
  payment_txns: Array<{
    wallet?: Wallet | null;
    shared_wallet?: SharedWallet | null;
    amount: string;
    id: string;
    status: string;
    type: PaymentTxnType;
  }>;
  amount: {
    charging_session_cost: number;
    pulse_processing_fee: number;
    pg_processing_fee: number;
    gst: number;
    state_tax: number;
    total: number;
    cost_breakup?: CostBreakupLineItem[];
    bill_items?: CostBreakupLineItem[];
  };
  gst_number: string | null;
  estimated_cost: any | {};
  generated_on: string;
  created_on: string;
  user_id: string;
  charge_point_id: string;
  cpo_id: string;
  unpaid_amount: number | null;
  applied_tariff_profiles: AppliedTariffProfile[];
  user?: MiniUser;
  charge_transactions?: ChargeTransaction[];
  charge_point: ChargePoint;
  invoice?: {
    url: string;
  };
  applied_coupons?: any[] | null; // TODO: change this to Coupon
};

export type PrimaryChargingStationConnector = {
  availability_last_updated_on: ISODateTime;
  available: 0;
  connector: {
    id: number;
    connector_type: string;
    current_type: 'DC' | 'AC';
    image_url: string;
    main_text: 'Fast' | 'Slow';
    name: string;
    order: number;
    speed_type: 'Fast' | 'Slow';
    sub_text: string;
    vehicle_types: string[];
  };
  id: number;
  power_rating: string;
  status: number; // 50
  total: number;
};

// 7.7
export enum ChargePointStatus {
  Available = 'Available',
  Preparing = 'Preparing',
  Charging = 'Charging',
  SuspendedEVSE = 'SuspendedEVSE',
  SuspendedEV = 'SuspendedEV',
  Finishing = 'Finishing',
  Reserved = 'Reserved',
  Unavailable = 'Unavailable',
  Faulted = 'Faulted'
}

export enum ChargePointErrorCode {
  ConnectorLockFailure = 'ConnectorLockFailure',
  EVCommunicationError = 'EVCommunicationError',
  GroundFailure = 'GroundFailure',
  HighTemperature = 'HighTemperature',
  InternalError = 'InternalError',
  LocalListConflict = 'LocalListConflict',
  NoError = 'NoError',
  OtherError = 'OtherError',
  OverCurrentFailure = 'OverCurrentFailure',
  OverVoltage = 'OverVoltage',
  PowerMeterFailure = 'PowerMeterFailure',
  PowerSwitchFailure = 'PowerSwitchFailure',
  ReaderFailure = 'ReaderFailure',
  ResetFailure = 'ResetFailure',
  UnderVoltage = 'UnderVoltage',
  WeakSignal = 'WeakSignal'
}

export enum CSFilterGroup {
  ConnectorTypes = 'connector_types',
  Amenities = 'amenities',
  Brands = 'brands',
  Payable = 'payable',
  Accessibility = 'accessibility'
}

export type FilterGroup = {
  name: string;
  value: CSFilterGroup;
  group_cover: {
    type: string;
    src: string;
  };
  flag: string;
  flag_color: string;
  flag_text_color: string;
};

export type AvailableFilters = {
  // [filter_name: string]: {
  [filter_group in CSFilterGroup]: {
    filter_subgroup: FilterSubGroupItem[];
  };
};

export type BillingDetails = {
  gstin: string;
  name?: string;
  email?: string;
  phone?: string;
  address?: string;
};

export type OrgConsoleTheme = {
  logo_url?: string;
  icon_url?: string;
  org_name?: string;
};
export enum AccessibilityType {
  Public = 10,
  Private = 20
}

export type Brand = {
  id: string;
  name: string;
  code: string;
  aiid: string;
  created_on: string;
  medias: any;
  additional_props?: BrandAdditionalProps;
};

export enum ChargingStationVariant {
  Stationary = '10',
  Mobile = '20'
}

export enum ChargingStationMobilityType {
  Stationary = '10',
  Mobile = '20'
}

export type CheckIn = {
  created_on: ISODateTime;
  current_lat: number | null;
  current_long: number | null;
  id: number;
  remark: string;
  status: 10 | 20 | 30;
  user: {
    id: 7;
    accent_color: '#C0592C';
    media: null;
    name: 'Pulse';
    nickname: null;
    dp: null;
  };
  user_vehicle: {
    id: number;
    vehicle: {
      charger_type: string | null;
      id: string;
      manufacturer: string;
      model: string;
      variant: string;
      max_range: number | null;
      validated: boolean;
      vehicle_type: '10' | '20' | '30' | '40' | '50';
      media?: {
        data?: {
          full_path: string;
        };
      };
    };
  };
  user_vehicle_id: number | null;
  vehicle: null;
};

export enum BillPaymentStatus {
  NotGenerated = '0', /// It will be used when the charging is kicked off
  Generated = '5', /// It will be used when the charging is stopped but connector is still connected
  Pending = '10', /// It will be used when the bill is generated with idle time fee but payment is pending
  Paid = '20',
  PartiallyPaid = '25',
  Failed = '30'
}

export enum BillPaymentStatusStr {
  NotGenerated = 'NotGenerated',
  Generated = 'Generated',
  Pending = 'Pending',
  Paid = 'Paid',
  PartiallyPaid = 'PartiallyPaid',
  Failed = 'Failed'
}

export enum BillPaymentMethod {
  CreditCard = '10',
  DebitCard = '20',
  UserWallet = '30',
  OrgWallet = '40',
  FleetOperatorWallet = '50',
  PaytmWallet = '60',
  WhatsApp = '70',
  UPI = '80',
  NetBanking = '90',
  Card = '100',
  RazorpayWallet = '110',
  EMI = '120'
}

export enum BillRefundStatus {
  Initiated = 'Initiated',
  Pending = 'Pending',
  Failed = 'Failed',
  Completed = 'Completed'
}

export enum PaymentTxnType {
  Recharge = '10',
  Transfer = '20',
  AutoPay = '30',
  Refund = '40',
  Block = '50', // Paytm Wallet
  Release = '60', // Paytm Wallet
  Captured = '70', // Paytm Wallet
  Payment = '100',
  // Deprecated
  Failed = '80',
  Pending = '90'
}

export type CostBreakupLineItem = {
  name: string;
  code: CostBreakupLineItemCode;
  value: number;
  note?: string;
  details?: CostBreakupLineItemDetailItem[];
};

export type AppliedTariffProfile = TariffProfile & {
  cost: number;
  from: ISODateTime;
  to: ISODateTime;
  bill_id: string;
  bill: Bill;
};

export type ConfigurationKeyValue = {
  key: string;
  value: string;
  readonly: boolean;
  status?: ConfigurationKeyValueStatus;
};

export type ConfigurationKey = ConfigurationKeyValue[];

export type UnknownKey = any[];

export type CPConfiguration = {
  configurationKey: ConfigurationKey;
  unknownKey?: UnknownKey;
};

export type FilterSubGroupItem = {
  filters: Filter[];
  name: string;
};

export type BrandAdditionalProps = {
  android_app_link?: string;
  ios_app_link?: string;
};

export enum CostBreakupLineItemCode {
  Total = 'total',
  ChargingSessionCost = 'charging_session_cost',
  GST = 'gst',
  ProcessingFee = 'pg_processing_fee',
  UnpaidAmount = 'previous_unpaid_amount',
  ParkingFee = 'parking_fee',
  PulseProcessingFee = 'pulse_processing_fee',
  ServiceFeeForMinutes = 'service_fee_for_minutes'
}

export type Filter = {
  viewValue: string;
  value: string;
  image: string;
  selected: boolean;
  flag: string;
  flag_color: string;
  flag_text_color: string;
  current_type?: string[];
  speed_type?: 'Fast' | 'Slow' | string;
  qf_rank: number;
  qf_visibility?: boolean;
  qf_icon_url?: string[] | null;
  // added locally
  group: CSFilterGroup | string;
  subgroup: string;
};

export type CostBreakupLineItemDetailItem = {
  bill_id?: string;
  bill_uid?: string;
  charge_transaction_id?: string;
  unpaid_amount?: number;
};

export type TariffProfile = {
  id: string;
  /**
   * Should be auto generated based on the type. Eg.: Base (24x7), ToD (07:00am - 12:00pm), Event (March 2 - March 4)
   */
  name: string;
  /**
   * Referenced in Bill.summary
   */
  profile_uid: string;
  state_tax: number;
  /**
   * [1,2,3,4,5,6,7]
   */
  applicable_days: number[];
  /**
   * default, ToD
   */
  type: TariffProfileTypeEnum;
  /**
   * in rupees
   */
  rate: number;
  /**
   * kW
   */
  rate_unit: string;
  /**
   * assumes that it'll be per minute
   */
  idle_fee: number;
  parking_fee: number;
  /**
   * applicable from
   */
  starts_at: ISODateTime | null;
  /**
   * applicable till
   */
  ends_at: ISODateTime | null;
  created_on: ISODateTime;
  org_id: string;
  priority: number;
  fleet_ids: string[];
  soc: number;
  costing_type: CostingType;
  applicable_to: 'selected' | 'all';
};

export enum ConfigurationKeyValueStatus {
  NotSupported = 'NotSupported',
  Rejected = 'Rejected'
}

export enum TariffProfileTypeEnum {
  Default = 'Default',
  ToD = 'ToD',
  Event = 'Event',
  SoC = 'SoC'
}

export enum CostingType {
  ChargingOnly = 10,
  ChargingAndParking = 20
}

export type ChargeTransactionAdditionalProps = {
  charging_source?: ChargingSource;
  app_variant?: AppVariantEnum;
  app_version?: string;
  protocol?: WhatsAppProtocol;
  payment_sdk?: PaymentSDK;
  payment_method?: PaymentMethodType;
  order_id?: string;
  link_id?: string;
  user_lat?: string;
  user_lng?: string;
  user_latitude?: string | number;
  user_longitude?: string | number;
  map_link?: string;
  meter_readings_assumed?: boolean;
  ev_latest_soc?: number;
  soc_entered_manually?: boolean;
  vehicle_number_entered_manually?: boolean;
  vehicle_soc_confirmed?: boolean;
  bap_id?: string;
  bap_uri?: string;
  vehicle_identification_number?: string;
  dummy_evre_user_id?: string;
  telematics_device_start_soc?: number;
  telematics_device_start_soc_timestamp?: string;
  telematics_device_stop_soc?: number;
  telematics_device_stop_soc_timestamp?: string;
  user_reported_start_soc?: number;
  user_reported_start_soc_timestamp?: string;
  retry_count?: number;
  previous_charge_transaction_id?: string;
  green_energy_selected?: boolean;
};

export enum AppVariantEnum {
  InstaCharge = 'instacharge',
  ThunderPlus = 'thunderplus',
  TorkMotors = 'torkmotors',
  ZeroFootprint = 'zerofootprint'
}

export enum PaymentSDK {
  Paytm = 'paytm',
  Razorpay = 'razorpay'
}

export enum RegistrationStatus {
  Accepted = 'Accepted',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export type MiniChargePoint = {
  id: string;
  public_uid: string;
  qr_code_id?: string;
  name: string;
  cs_id: string;
  accessibility: AccessibilityType;
  charging_station?: CentralChargingStation;
};

export type MiniOEM = {
  id: string;
  name: string;
};

export enum ConnectivityStatus {
  Online = 'Online',
  Offline = 'Offline'
}

export type ChargingStationDetailScreenRouteParams = {
  chargerIdType: 'charge-point' | 'charging-station';
  isDeepLinked: boolean;
  chargerId: string | number;
};

export type ChargingStatesScreenRouteParams = {
  startChargingData: StartChargingPayload;
  selectedChargePointConnector: ChargePointConnector | undefined;
  requiredBalance: number;
};

export type ChargingStartingSoonScreenRouteParams = {
  startChargingData: StartChargingPayload;
  selectedChargePointConnector: ChargePointConnector | undefined;
  requiredBalance: number;
};

export type InsufficientWalletBalanceScreenRouteParams = {
  wallet: Wallet;
  requiredBalance: number;
  returnScreen?: string | null;
};

export type SwitchPaymentMethodScreenRouteParams = {
  returnScreen?: string | null;
};

export type AddMoneyToWalletScreenRouteParams = {
  walletId: string;
  returnScreen?: string | null;
  walletType: WalletType;
};

export type I18NRichMessageResponse = {
  [LangCodes.en]: RichMessageResponse;
  [LangCodes.hi]: RichMessageResponse;
  [LangCodes.kn]: RichMessageResponse;
  [LangCodes.bn]?: RichMessageResponse;
  [LangCodes.gu]?: RichMessageResponse;
  [LangCodes.mr]?: RichMessageResponse;
  [LangCodes.te]?: RichMessageResponse;
  [LangCodes.ta]?: RichMessageResponse;
  [LangCodes.ml]?: RichMessageResponse;
  [LangCodes.empty]?: RichMessageResponse;
};

export type RichMessageResponse = {
  main_txt: string;
  sub_txt?: string;
  type?: 'error' | 'warn' | 'info' | 'success';
  actions?: Array<{
    btn_txt: string;
    close_btn_txt: string;
    type: string;
    info: string;
  }>;
};

export type AlertOptionsType = {
  title?: string;
  message?: string;
  alertOptions?: {
    cancelable: boolean;
  };
  cancelButtonText?: string;
  cancelButtonStyle?: 'cancel' | 'default' | 'destructive';
  cancelButtonOnPress?: () => void;
  affirmativeButtonText?: string;
  affirmativeButtonStyle?: 'cancel' | 'default' | 'destructive';
  affirmativeButtonOnPress?: () => void;
};

export type PaymentModeSelectionScreenRouteParams = {
  returnScreen?: string | null;
  showBackButton?: boolean;
  showLogoutButton?: boolean;
};

export type PaymentModeLinkWalletScreenRouteParams = {
  nextScreen?: string | null;
  returnScreen?: string | null;
};

export type ManageWalletAccessScreenRouteParams = {
  walletId: string;
  returnScreen?: string | null;
};

export type ExternalWalletPhoneNumberScreenRouteParams = {
  returnScreen?: string | null;
  nextScreen?: string | null;
  walletType?: WalletType;
};

export type ExternalWalletVerifyOTPScreenRouteParams = {
  returnScreen?: string | null;
  nextScreen?: string | null;
  number: string;
  wallet_id?: string;
};

export type AppliedFiltersStr = {
  [filter_group in CSFilterGroup]?: string;
};

export const UPDATE_CHARGE_TXNS_LIST = 'UPDATE_CHARGE_TXNS_LIST';
export const UPDATE_CHARGE_TXNS_LIST_PAGINATION = 'UPDATE_CHARGE_TXNS_LIST_PAGINATION';
export const SET_CHARGE_TXNS_FILTERS = 'SET_CHARGE_TXNS_FILTERS';

export const UPDATE_ACTIVE_PAYMENT_METHOD_TYPE = 'UPDATE_ACTIVE_PAYMENT_METHOD_TYPE';
export const UPDATE_ACTIVE_WALLET = 'UPDATE_ACTIVE_WALLET';
export const UPDATE_FLEET_WALLETS = 'UPDATE_FLEET_WALLETS';
export const UPDATE_PERSONAL_WALLETS = 'UPDATE_PERSONAL_WALLETS';
export const UPDATE_SHARED_WALLETS = 'UPDATE_SHARED_WALLETS';
export const UPDATE_WALLET_CONSUMERS = 'UPDATE_WALLET_CONSUMERS';
export const REMOVE_SHARED_WALLET_FROM_ACTIVE_WALLET = 'REMOVE_SHARED_WALLET_FROM_ACTIVE_WALLET';

export const UPDATE_SINGLE_WALLET_CONSUMER = 'UPDATE_SINGLE_WALLET_CONSUMER';
export const REMOVE_WALLET_CONSUMER = 'REMOVE_WALLET_CONSUMER';

export const SET_AVAILABLE_CS_FILTERS = 'SET_AVAILABLE_CS_FILTERS';
export const SET_CONNECTOR_TYPES_LIST = 'SET_CONNECTOR_TYPES_LIST';
export const SET_APPLIED_FILTERS = 'SET_APPLIED_FILTERS';
export const SET_CUSTOM_FILTERS_APPLIED = 'SET_CUSTOM_FILTERS_APPLIED';
export const SET_APPLIED_FILTERS_CHANGE_VERSION = 'SET_APPLIED_FILTERS_CHANGE_VERSION';

export const SET_APP_STATE = 'SET_APP_STATE';
export const SET_APP_INITIALIZED = 'SET_APP_INITIALIZED';
export const SET_USER_AUTHENTICATED = 'SET_USER_AUTHENTICATED';
export const SET_LOCATION_PERMISSION_ENABLED = 'SET_LOCATION_PERMISSION_ENABLED';
export const SET_GPS_ENABLED = 'SET_GPS_ENABLED';
export const SET_USERS_PHYSICAL_LOCATION = 'SET_USERS_PHYSICAL_LOCATION';
export const SET_MAP_COORDS = 'SET_MAP_COORDS';
export const UPDATE_ALLOWED_QR_CODE_URL_PATTERNS = 'UPDATE_ALLOWED_QR_CODE_URL_PATTERNS';
export const UPDATE_FIREBASE_REMOTE_CONFIG_VALUES = 'UPDATE_FIREBASE_REMOTE_CONFIG_VALUES';
export const SET_EVALUATED_APP_UPDATE = 'SET_EVALUATED_APP_UPDATE';
export const SET_SHOW_AC_CHARGERS_ONLY = 'SET_SHOW_AC_CHARGERS_ONLY';
export const SET_SHOULD_UPDATE_USER_PROFILE = 'SET_SHOULD_UPDATE_USER_PROFILE';
export const UPDATE_SHOULD_FETCH_WALLET_BALANCE = 'UPDATE_SHOULD_FETCH_WALLET_BALANCE';
export const RESET_STORE = 'RESET_STORE';

export const SET_NEARTEST_CHARGER = 'auth/SET_NEARTEST_CHARGER';
export const SET_TRACKED_NEAREST_CHARGING_STATION = 'auth/SET_TRACKED_NEAREST_CHARGING_STATION';
export const SET_LAST_CHARGE_TRANSACTION = 'SET_LAST_CHARGE_TRANSACTION';
export const SET_NEARBY_CHARGING_STATIONS = 'auth/SET_NEARBY_CHARGING_STATIONS';
export const SET_RECENT_CHARGER = 'auth/SET_RECENT_CHARGER';
export const SET_FILTER_DATA = 'auth/SET_FILTER_DATA';
export const RESET_DATA = 'auth/RESET_DATA';
export const SET_SELECTED_STATION = 'auth/SET_SELECTED_STATION';
export const SET_SELECTED_CHARGER = 'auth/SET_SELECTED_CHARGER';
export const SET_SELECTED_CHARGER_POINT = 'auth/SET_SELECTED_CHARGER_POINT';
export const SET_FILTER_OPTION = 'auth/SET_FILTER_OPTION';

// maps
export const UPDATE_MAPS_NEARBY_UCS = 'UPDATE_MAPS_NEARBY_UCS';
export const RESET_MAPS_NEARBY_UCS = 'RESET_MAPS_NEARBY_UCS';
export const UPDATE_UCS_MASTER_MAP = 'UPDATE_UCS_MASTER_MAP';

export const SET_HOME_SCREEN_NEARBY_UCS_PAGINATION = 'SET_HOME_SCREEN_NEARBY_UCS_PAGINATION';

export const UPDATE_NEAREST_UCS_MINI_OBJECT = 'UPDATE_NEAREST_UCS_MINI_OBJECT';
export const UPDATE_NEARBY_UCS_MINI_OBJECTS_LIST = 'UPDATE_NEARBY_UCS_MINI_OBJECTS_LIST';
export const RESET_NEAREST_UCS_MINI_OBJECT = 'RESET_NEAREST_UCS_MINI_OBJECT';
export const RESET_NEARBY_UCS_MINI_OBJECTS_LIST = 'RESET_NEARBY_UCS_MINI_OBJECTS_LIST';

export const SET_SELECTED_CHARGING_STATION = 'SET_SELECTED_CHARGING_STATION';
export const SET_SELECTED_MIXED_CHARGING_STATION = 'SET_SELECTED_MIXED_CHARGING_STATION';
export const SET_SELECTED_CHARGE_POINT = 'SET_SELECTED_CHARGE_POINT';
export const SET_SELECTED_CHARGE_POINT_CONNECTOR = 'SET_SELECTED_CHARGE_POINT_CONNECTOR';

export const SET_TRACKED_CP_CONNECTION_ERR = 'SET_TRACKED_CP_CONNECTION_ERR';
export const SET_TRACKED_CP_ERR_CONNECTOR = 'SET_TRACKED_CP_ERR_CONNECTOR';

export const UPDATE_ONGOING_CHARGE_TXNS = 'UPDATE_ONGOING_CHARGE_TXNS';
export const UPDATE_ONGOING_CHARGE_TXN_BY_ID = 'UPDATE_ONGOING_CHARGE_TXN_BY_ID';

export const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES';

export type MapsScreenRouteParams = {
  type?: 'search' | 'filter';
  changeId?: string;
  place?: any;
};

export type ScreenComponent = {
  navigation: any;
  route: Route<any>;
};

export type EnterAccessCodeScreenRouteParams = {
  nextScreen?: string | null;
};

export type PartialLoginEnterAccessCodeScreenRouteParams = {
  nextScreen?: string | null;
};

export type FiltersScreenRouteParams = {
  context?: 'maps_screen' | 'home_screen' | 'search_screen',
  filterGroupToOpen?: CSFilterGroup
};
