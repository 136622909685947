const config = {
  WHITE_LABEL_ID: 'instacharge',
  APP_ENV: process.env.REACT_APP_ENV,
  API_APP_VARIANT: 'instacharge_suph79gtgk9z9ft',
  PLAY_STORE_APP_URL: 'https://play.google.com/store/apps/details?id=com.pulseenergy.instacharge',
  APP_STORE_APP_URL: 'https://apps.apple.com/tt/app/instacharge-simple-ev-charging/id1627221988',
  APP_NAME: 'instacharge',
  BUNDLE_ID: 'com.instacharge'
};

export default config;
