import { ENVIRONMENT } from '../config/setting';
import { GLOBAL_VARS } from '../constants';
import { ToastContainer, ToastPosition, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Platform } from 'react-native';
import { Coords } from '../central-atoms/types';
import { roundNumber } from '../central-atoms/functions/utils';
import { ToastOptionsType } from '../miscellaneous/types';
import { COLORS } from '../config/colors';

export function isIOS(): boolean {
  return Platform.OS === 'ios';
}

export function getKeyboardAvoidingViewBehavior(): 'padding' | 'height' | 'position' | undefined {
  return Platform.OS === 'ios' ? 'padding' : undefined;
}

export function getGlobalVar(variable: string): any {
  // @ts-ignore
  return global[variable];
}

export function isAndroid(): boolean {
  return Platform.OS === 'android';
}

export function setGlobalVar(variable: string, value: any): any {
  // @ts-ignore
  global[variable] = value;
}

export const doCoordsMatch = (coordsA: Coords, coordsB: Coords): boolean => {
  if (coordsA.latitude && coordsA.longitude && coordsB.latitude && coordsB.longitude) {
    const stateCoordsLat = roundNumber(coordsA.latitude, 1000);
    const stateCoordsLng = roundNumber(coordsA.longitude, 1000);
    const newCoordsLat = roundNumber(coordsB.latitude, 1000);
    const newCoordsLng = roundNumber(coordsB.longitude, 1000);

    return stateCoordsLat === newCoordsLat && stateCoordsLng === newCoordsLng;
  }
  return false;
};

export function showSuccessToast(msg: string, options: any = {}, extraData?: any) {
  const defaultOptions = {
    position: toast.POSITION.TOP_CENTER as ToastPosition,
    type: toast.TYPE.SUCCESS,
    autoClose: 5000 as number,
    hideProgressBar: true,
    style: {
      backgroundColor: COLORS.green600,
      borderRadius: 10,
      marginTop: 10,
      color: COLORS.whiteColor,
      fontWeight: 600,
      marginRight: 10,
      marginLeft: 10
    }
  };
  toast.success(msg, { ...defaultOptions, ...options });
}

export function showInfoToast(msg: string, options: any = {}, extraData?: any) {
  const defaultOptions = {
    position: toast.POSITION.TOP_CENTER as ToastPosition,
    type: toast.TYPE.INFO,
    autoClose: 5000 as number,
    hideProgressBar: true,
    style: {
      backgroundColor: COLORS.blue400,
      borderRadius: 10,
      marginTop: 10,
      color: COLORS.whiteColor,
      fontWeight: 600,
      marginRight: 10,
      marginLeft: 10
    }
  };
  toast.info(msg, { ...defaultOptions, ...options });
}

export function showErrorToast(msg: string, options: any = {}, extraData?: any) {
  const defaultOptions = {
    position: toast.POSITION.TOP_CENTER as ToastPosition,
    type: toast.TYPE.ERROR,
    autoClose: 5000 as number,
    hideProgressBar: true,
    style: {
      backgroundColor: COLORS.brightRed,
      borderRadius: 10,
      marginTop: 10,
      color: COLORS.whiteColor,
      fontWeight: 600,
      marginRight: 10,
      marginLeft: 10
    }
  };
  toast.error(msg, { ...defaultOptions, ...options });
}

export function showWarnToast(msg: string, options: any = {}, extraData?: any) {
  const defaultOptions = {
    position: toast.POSITION.TOP_CENTER as ToastPosition,
    type: toast.TYPE.WARNING,
    autoClose: 5000 as number,
    hideProgressBar: true,
    style: {
      backgroundColor: COLORS.yellow400,
      borderRadius: 10,
      marginTop: 10,
      color: COLORS.whiteColor,
      fontWeight: 600,
      marginRight: 10,
      marginLeft: 10
    }
  };
  toast.warn(msg, { defaultOptions, ...options });
}

export function showToast(msg: string, options: any = {}, extraData?: any) {
  const { type, ...option } = options;
  if (type === 'error') {
    showErrorToast(msg, option);
  } else if (type === 'info') {
    showInfoToast(msg, option);
  } else if (type === 'success') {
    showSuccessToast(msg, option);
  } else if (type === 'warning') {
    showWarnToast(msg, option);
  }
  // toast({
  //   type: 'error',
  //   text1: msg,
  //   position: 'bottom',
  //   visibilityTime: 5000,
  //   autoClose: true,
  //   bottomOffset: 80,
  //   ...options
  // });

  if (extraData && ENVIRONMENT.type !== 'prod') setGlobalVar(GLOBAL_VARS.currentError, extraData);
  else if (!!extraData) setGlobalVar(GLOBAL_VARS.currentError, null);
}
