// Upload file group type
export enum UploadFileGroup {
    UserProfile = 'user-profile',
    OrgLogo = 'org-logo',
    ChargingStations = 'charging-stations',
    ChargePoints = 'charge-points',
    BrandLogo = 'brand-logo',
    LocationPinPrivate = 'location-pin-private',
    LocationPinPrivateBig = 'location-pin-private-big',
    LocationPinCloud = 'location-pin-cloud',
    LocationPin = 'location-pin',
    LocationPinCloudBig = 'location-pin-cloud-big', // 'location-pin-cloud-big'
    LocationPinBig = 'location-pin-big', // 'location-pin-big'
    ConnectorLogo = 'connector-logo',
    PaymentReceipt = 'payment-receipt',
    VehicleOwner = 'vehicle-owner',
    CancelledCheque = 'cancelled-cheque',
    InvoiceCompanyLogo = 'invoice-company-logo'
}

export enum UploadFileGroupPrefix {
    UserProfileMedia = 'upm',
    OrgLogo = 'ol',
    ChargingStations = 'cs',
    ChargePoints = 'cp',
    BrandLogo = 'bl',
    LocationPinPrivate = 'lpp',
    LocationPinPrivateBig = 'lppb',
    LocationPinCloud = 'lpc',
    LocationPin = 'lp',
    LocationPinCloudBig = 'lpcb',
    LocationPinBig = 'lpb',
    ConnectorLogo = 'conl',
    PaymentReceipt = 'pr',
    VehicleOwner = 'vrn',
    CancelledCheque = 'cq',
    InvoiceCompanyLogo = 'icl'
}

export enum MediaType {
    Logo = 'logo',
    CPImg = 'cp-img',
    CSImg = 'cs-img',
    VRNImg = 'vrn-img',
    DP = 'dp',
    LocationPinPrivate = 'location-pin-private',
    LocationPinPrivateBig = 'location-pin-private-big',
    LocationPinCloud = 'location-pin-cloud',
    LocationPinCloudBig = 'location-pin-cloud-big', // 'location-pin-cloud-big'
    LocationPin = 'location-pin',
    LocationPinBig = 'location-pin-big', // 'location-pin-big'
    CancelledCheque = 'cancelled-cheque',
    InvoiceCompanyLogo = 'invoice-company-logo'
}
