export enum ChargeTransactionStatus {
    Ongoing = 'Ongoing',
    Stopped = 'Stopped',
    Pending = 'Pending',
    Unknown = 'Unknown',
    Invalid = 'Invalid',
    Reservation = 'Reservation',
    Initiated = 'Initiated',
}

export enum ChargingSource {
    WhatsApp = 'WhatsApp',
    App = 'App',
    Console = 'Console',
    Charger = 'Charger',
    EVRE_RFID = 'EVRE_RFID',
    OCPI_RFID = 'OCPI_RFID',
    Vehicle = 'Vehicle',
}

export enum WhatsAppProtocol {
    UEI = 'uei',
    Everest = 'everest',
    InstaCharge = 'instacharge',
}
