export enum ControllabilityType {
    OCPPControl = 10,
    ViewOnly = 20,
    APIControl = 30,
    OCPIControl = 40,
}

export enum ConnectivityStatus {
    Online = 'Online',
    Offline = 'Offline'
}