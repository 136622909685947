export enum BillPaymentMethod {
    CreditCard = "10",
    DebitCard = "20",
    UserWallet = "30",
    OrgWallet = "40",
    FleetOperatorWallet = "50",
    PaytmWallet = "60",
    WhatsApp = "70",
    UPI = "80",
    NetBanking = "90",
    Card = "100",
    RazorpayWallet = "110",
    EMI = "120",
    NumadicFastagWallet = "150",
}

export enum BillPaymentStatus {
    NotGenerated = "0", /// It will be used when the charging is kicked off
    Generated = "5", /// It will be used when the charging is stopped but connector is still connected
    Pending = "10", /// It will be used when the bill is generated with idle time fee but payment is pending
    Paid = "20",
    PartiallyPaid = "25",
    Failed = "30",
}

export enum BillPaymentStatusStr {
    NotGenerated = "NotGenerated",
    Generated = "Generated",
    Pending = "Pending",
    Paid = "Paid",
    PartiallyPaid = "PartiallyPaid",
    Failed = "Failed",
}
