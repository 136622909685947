import HttpRequestService from "../config/HttpRequestService";
import { CENTRAL_HOST } from "../config/setting";
import { GLOBAL_VARS } from "../constants";
import { getGlobalVar } from "../miscellaneous/helpers";

export default class FiltersRequestService {
    static async fetchCSFilters(): Promise<any> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return await centralHttpRequest.getInstance()
            .get(`${CENTRAL_HOST}/charging_station/filters`)
            .then((response: any) => {
                return response.data;
            });
    }

    static async fetchCSFiltersCount(): Promise<any> {
        const centralHttpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.centralHttpRequest);
        return await centralHttpRequest.getInstance()
            .get(`${CENTRAL_HOST}/charging_station/filters/count`)
            .then((response: any) => {
                return response.data;
            });
    }
}