// eslint-disable-next-line import/prefer-default-export
export enum LangCodes {
    en = 'en',
    hi = 'hi',
    kn = 'kn',
    bn = 'bn',
    ml = 'ml',
    mr = 'mr',
    gu = 'gu',
    ta = 'ta',
    te = 'te',
    empty = '',
};
