export const convertListToMap = (inputList: string[]): { [listElement: string]: boolean } => {
    const outputMap: { [listElement: string]: boolean } = {};
    inputList.forEach((input) => {
        outputMap[input] = true;
    });

    return outputMap;
};

export const convertSetToMap = (inputList: Set<string>): { [listElement: string]: boolean } => {
    const outputMap: { [listElement: string]: boolean } = {};
    inputList.forEach((input) => {
        outputMap[input] = true;
    });

    return outputMap;
};

export const roundNumber = (num: number, decimalPlaces = 100): number => {
    return Math.round((num + Number.EPSILON) * decimalPlaces) / decimalPlaces;
}

export function getGrammaticallyNumberizedText(singularText: string, pluralText: string, count: number): string {
    return count === 1 ? singularText : pluralText;
}