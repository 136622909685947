import { encode as btoa } from "base-64";
import { AppliedFilters, AppliedFiltersStr, CSFilterGroup, CSFiltersState } from "../types";
import HttpRequestService from "../config/HttpRequestService";
import { getGlobalVar } from "../miscellaneous/helpers";
import { GLOBAL_VARS } from "../constants";
import { API_BASE_PATH } from "../config/setting";

export class FiltersService {
    static fetchCSFiltersCount(params: any): Promise<number> {
        const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
        
        return httpRequest?.getInstance()
            .put(`${API_BASE_PATH}/central_charging_station/filters/count`, {
                ...params
            })
            .then((response) => {
                return response.data.data;
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    }

    static fetchCSFilters(): Promise<{data: CSFiltersState}> {
        const httpRequest: HttpRequestService = getGlobalVar(GLOBAL_VARS.httpRequest);
        return httpRequest?.getInstance()
            .get(`${API_BASE_PATH}/central_charging_station/filters`)
            .then((response) => {
                return response.data;
            })
            .catch((e) => {
                return Promise.reject(e);
            });
    }

    static getAppliedFiltersParams(appliedFilters: AppliedFilters): AppliedFiltersStr {
        const parsedFilters: AppliedFiltersStr = {};
        Object.keys(appliedFilters).forEach((key) => {
            if (appliedFilters[key as CSFilterGroup]?.length) {
                parsedFilters[key as CSFilterGroup] = appliedFilters[key as CSFilterGroup].join(',');
            }
        });

        return parsedFilters;
    }

    static getExclusionList(csIds: number[]): string {
        return btoa(csIds.join(','));
    }

    static getTotalAppliedFilters(appliedFilters: AppliedFilters): number {
        let appliedCount = 0;
        Object.keys(appliedFilters).forEach((csFilterGroup) => {
            appliedCount += (appliedFilters[csFilterGroup as CSFilterGroup]?.length || 0);
        });
        return appliedCount;
    }

    static getAppliedFiltersGroupCount(appliedFilters: AppliedFilters): {[key: string]: number} {
        const filtersGroup: {[key: string]: number} = {};
        Object.keys(appliedFilters).forEach((csFilterGroup: string) => {
            filtersGroup[csFilterGroup] = (appliedFilters[csFilterGroup as CSFilterGroup]?.length || 0);
        });
        return filtersGroup;
    }
}